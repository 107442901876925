import { postAPI } from '../utils'
import axios from 'axios';


// Use dummy function as default
export default function pleaseStopMessingWithThis() {
    console.log('You shouldn\'t be here')
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function postNode(input_object) {
    var body = {
        node: {
            ...(input_object.title && { title: input_object.title }),
            ...(input_object.type && { type: input_object.type }),
            // ...(input_object.content && { content: input_object.content }),
        }
    }
    return new Promise(function(resolve, reject) {
        postAPI('/node', body)
        .then((res) => {
            console.log("in promise then")
            console.log(res)
            try {
                if (!input_object.content) {return}
                if (!res.data.content_update_url) {return}
                console.log("trying content post")
                var requestConfig = {
                    headers: {
                        'accept': "application/json",
                        'Content-Type': 'application/json',
                    }
                }
                var response = axios.put(res.data.content_update_url, input_object.content, requestConfig)
                .then((res)=>{resolve(res)})
                .catch((err)=>{
                    console.log("sub error:")
                    console.log(err)
                })
                console.log(response)
            } catch (error) {
                console.log("error in content post")
                console.log(error)
                reject(error)
            }
        })
        .catch((err) => {
            console.log("in promise catch")
            console.log(err)
            reject(err)
        })
    })
    
}

export function getAllNodes() {
    var body = {
        operation: "get_all_nodes"
    }
    return postAPI('/node', body)
}

export function deleteAllNodes() {
    var body = {
        operation: "delete_all_nodes"
    }
    return postAPI('/node', body)
}

export function deleteNode(node_id) {
    var body = {
        operation: "delete_node", 
        node_id: node_id
    }
    return postAPI('/node', body)
}

export function formatGraphForCytoscape(graph) {
    var elements = []
    if (graph.nodes) {
        for (let node of graph.nodes) {
            // console.log(node)
            node.label = node.title || ""
            elements.push({data: node})
        }
    }
    if (graph.edges) {
        for (let edge of graph.edges) {
            edge.label = edge.type || "placeholder"
            elements.push({data: edge})
        }
    }
    return elements
}