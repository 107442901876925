import React, { useEffect } from 'react'
import { Widget, addResponseMessage, addUserMessage, setQuickButtons, deleteMessages, toggleWidget, markAllAsRead, addLinkSnippet } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

// eslint-disable-next-line
export default function (props) {

    // Interactions with the parent page
    const postToParent = (message_type, body) => {
        let message = {
            source: "chat_widget",
            message_type: message_type,
            body: body
        }
        window.postMessage(message, "*");
    }

    const generateEventListener = () => {
        window.addEventListener("message", onWebSocketMessage);
    }
    // Standard actions: 
    const setDefaultButtons = () => {
        setQuickButtons([
            {
                label:"Send Selected",
                value:{action: "agent"}
            },
        ])
    }

    // Types of events from the server
    const handleServerMessage = (chat_event) => {
        console.log("Server Message")
        console.log(chat_event)
        chat_event.events.forEach(event => {
            if (event.type === "text") {
                if (!event.content.startsWith("__")) {
                    addResponseMessage(event.content)
                }
            }
            if (event.type === "link") {
                addLinkSnippet({
                    link: event.content.link, 
                    title: event.content.title,
                    target: event.content.target || "_blank"
                })
            }
        })
    }
    const handleUserMessage = (chat_event) => {
        console.log("User Message")
        console.log(chat_event)
        chat_event.events.forEach(event => {
            if (event.type === "text") {
                addUserMessage(event.content)
            }
        })
    }
    const handleChatHistory = React.useCallback((body) => {
        deleteMessages(1000)
        if (body.chat_history.length === 0){
            addResponseMessage("Welcome to the betalake, I am the NLU assistant, how can I help?")
        }
        else {
            deleteMessages(1000)
            body.chat_history.reverse().forEach(chat_event => {
                if (chat_event.type === "system_message") {
                    handleServerMessage(chat_event)
                }
                if (chat_event.type === "user_message") {
                    handleUserMessage(chat_event)
                }
            }) 
            markAllAsRead()
        }
    }, [])

    const onWebSocketMessage = React.useCallback((event) => {
        // if (event.data === undefined) {return}
        // if (event.data.source.startsWith("react-devtools")) {return}
        try {
            let data = JSON.parse(event.data)
            // postToParent("server_message", data)
            console.log("data")
            console.log(data)
            console.log("message_type")
            console.log(data.message_type)
            // addResponseMessage(JSON.stringify(data))
            if (data.message_type === "system_message"){
                handleServerMessage(data)
            }
            if (data.message_type === "user_message"){
                handleUserMessage(data)
            }
            if (data.message_type === "chat_history") {
                handleChatHistory(data)
            }
        } catch (error) {
            // console.log(event)
            return
        }
        
        
    }, [handleChatHistory])

    // Interacton with the back end
    const sendMessageToBackend = (message) => {
        let body = {
            "events": [
                {
                    "type": "text",
                    "content": message
                }
            ]
        }
        postToParent("client_message", body)
    }

    // How to react to user actions
    const handleNewUserMessage = (event) => {
        console.log(`New message incoming! ${event}`);
        sendMessageToBackend(event)
    };
    const handleQuickButtonClicked = (event) => {
        console.log("quick button clicked")
        console.log(event)
    }


    // Run Once on final load
    useEffect(() => {
        generateEventListener()
        if (props.defaultOpen === true) {
            toggleWidget()
        }
        setDefaultButtons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
            <Widget 
                title="NLU Assistant"
                subtitle = ""
                handleNewUserMessage={handleNewUserMessage}
                showTimeStamp={false}
                handleQuickButtonClicked={handleQuickButtonClicked}
                {...props}
            />
    )
}
