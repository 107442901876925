import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import cytoscape from 'cytoscape';
import cola from 'cytoscape-cola';
import cxtmenu from 'cytoscape-cxtmenu';
import edgehandles from 'cytoscape-edgehandles';

import * as Betalake from '../data/Betalake'
import * as util from '../utils'


cytoscape.use(cola);
cytoscape.use(edgehandles);
cytoscape.use(cxtmenu);

const elements = []
//     { data: { id: 'parent', label: 'parent' }, },
//     { data: { id: 'one', label: 'Node 1', parent: 'parent' }, },
//     { data: { id: 'two', label: 'Node 2', parent: 'parent' }, },
//     { data: { source: 'one', target: 'two', label: 'Edge from Node1 to Node2' } }
// ]

const layout = {
    name: "cola",
    fit: true,
    directed: true,
    animate: true,
    // animationDuration: 10000,
    infinite: true,
    avoidOverlap: true,
    nodeDimensionsIncludeLabels: false,
    // centerGraph: false
};

const cytoscyle = [{
    selector: 'node',
    css: {
        'label': 'data(label)',
        'text-valign': 'bottom',
        'text-halign': 'center',
        'height': '60px',
        'width': '60px',
        'border-opacity': '1',
        "text-background-opacity": 1,
        "text-background-color": "lightgray"
    }
},
{
    selector: 'edge',
    style: {
      'label': 'data(label)' // maps to data.label
    }
  },
{
    selector: ':selected',
    css: {
        'background-color': 'black',
        'line-color': 'black',
        'target-arrow-color': 'black',
        'source-arrow-color': 'black'
    }
}
]

const cyto_container_style = {
    height: "100vh",
    overflow: "hidden",
    textAlign: "left",
    position: "relative"
    // super important, otherwise the canvas renders 
    // offset halfway off the screen
}


// eslint-disable-next-line
export default forwardRef((props, ref) => {

    const [graph, setGraph] = useState({ nodes: [], edges: [] })

    const containerRef = useRef();
    var cy = useRef()
    var edgehandles = useRef()
    const logging_enabled = false
    const context_menu = {
        menuRadius: function (ele) { return 50; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
        selector: 'node, edge', // elements matching this Cytoscape.js selector will trigger cxtmenus
        outsideMenuCancel: 10,
        commands: [
            {
                content: 'Delete',
                select: function (ele) {
                    sendRemovalRequest(ele)
                }
            },
            {
                content: 'log',
                select: function (ele) {
                    util.log(ele)
                }
            }
        ],
    };
    let edgehandles_defaults = {
        canConnect: function (sourceNode, targetNode) {
            // whether an edge can be created between source and target
            return !sourceNode.same(targetNode); // e.g. disallow loops
        },
        edgeParams: function (sourceNode, targetNode) {
            // for edges between the specified source and target
            // return element object to be passed to cy.add() for edge
            return {};
        },
        hoverDelay: 150, // time spent hovering over a target node before it is considered selected
        snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
        snapThreshold: 10, //50, // the target node must be less than or equal to this many pixels away from the cursor/finger
        snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
        noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
        disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };



    // This is how you customize the functions visible to the ref in the parent component
    useImperativeHandle(ref, () => ({
        // addCytodata(data) {
        //     addDataToGraph(data)
        // },
        removeElementFromGraph(element_id) {
            removeElementFromGraph(element_id)
        },
        enableEdgeDrawingMode() {
            enableEdgeDrawingMode()
        },
        disableEdgeDrawingMode() {
            disableEdgeDrawingMode()
        },
        toggleEdgeDrawingMode() {
            toggleEdgeDrawingMode()
        },
        addDataToGraph(add_graph) {
            updateGraph(add_graph)
        },
        animate() {
            runAnimation()
        }
    }));

    const runAnimation = React.useCallback(() => {
        // cy.current.layout(layout).stop()
        cy.current.layout(layout).run()
    }, [cy])

    const addDataToGraph = React.useCallback((cytodata) => {
        for (let element of cytodata) {
            var target_element = cy.current.$(`#${element.id}`)
            if (target_element.length > 0) {
            } else {
                try {
                    let el = cy.current.add(element)
                    el.layout.run()
                } catch (e) {
                    util.log(e, logging_enabled)
                }
            }
            // runAnimation()
        }
        runAnimation()

    }, [cy, logging_enabled, runAnimation])

    const updateGraph = React.useCallback((add_graph) => {
        console.log("!!!!!!!!!!!!!!!update graph")
        var temp_graph = graph
        console.log("temp_graph", temp_graph)
        console.log("add_graph", add_graph)

        if (add_graph.nodes) {
            console.log("there are some Nodes")
            add_graph.nodes.forEach(node => {
                temp_graph.nodes.push(node)
            })
        }
        if (add_graph.edges) {
            console.log("there are some Edges")
            add_graph.edges.forEach(edge => {
                temp_graph.edges.push(edge)
            })
        }

        console.log("temp_graph", temp_graph)
        let cytodata = Betalake.formatGraphForCytoscape(temp_graph)
        addDataToGraph(cytodata)
        setGraph(temp_graph)
    }, [graph, setGraph, addDataToGraph])

    const enableEdgeDrawingMode = () => {
        edgehandles.current.enable();
        edgehandles.current.enableDrawMode();
    }
    const disableEdgeDrawingMode = () => {
        edgehandles.current.disable();
        edgehandles.current.disableDrawMode();
    }
    const toggleEdgeDrawingMode = () => {
        if (edgehandles.current.enabled) {
            disableEdgeDrawingMode()
        } else {
            enableEdgeDrawingMode()
        }
    }

    const removeElementFromGraph = React.useCallback((target_element) => {
        // remove the element from my external tracking graph first
        try {
            var temp_graph = graph
            for (let node of temp_graph.nodes) {
                if (node.id === target_element) {
                    temp_graph.nodes.splice(temp_graph.nodes.indexOf(node), 1)
                }
            }
            for (let edge of temp_graph.edges) {
                if (edge.id === target_element) {
                    temp_graph.edges.splice(temp_graph.edges.indexOf(edge), 1)
                }
            }
            setGraph(temp_graph)
        } catch (e) {
            util.log(e, logging_enabled)
        }
        // remove the element from the cytoscape graph
        try {
            target_element = cy.current.$(`#${target_element}`)
            target_element.remove()
            
        } catch (e) {
            util.log(e, logging_enabled)
        }
        //runAnimation()
    }, [cy, graph, logging_enabled, setGraph, runAnimation])

    const sendRemovalRequest = React.useCallback((target_element) => {
        try {
            console.log("element_to_remove", target_element)
            let element = target_element.json()

            console.log("the id", element.data.id)
            console.log("element json", element)
            if (element.group === "nodes") {
                props.removeNode(element.data.id)
            }
            else if (element.group === "edges") {
                props.removeEdge(element.data.id)
            }
        } catch (e) {
            util.log(e, logging_enabled)
        }
    }, [props, logging_enabled])

    useEffect(() => {
        const config = {
            container: containerRef.current,
            elements: elements,
            layout: { layout },
            animate: true,
            fit: true,
            style: cytoscyle,
        };
        cy.current = cytoscape(config);
        var menu = cy.current.cxtmenu(context_menu);
        edgehandles.current = cy.current.edgehandles(edgehandles_defaults);
        util.log(menu, logging_enabled)
        //cy.current.layout(layout).run()
        runAnimation()
        disableEdgeDrawingMode()

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div ref={containerRef}
                style={cyto_container_style}
            />
        </>
    )
})
