import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import auth from '../pages/auth';
import Iframe from '../pages/Iframe';
import widget from '../pages/widget';
import cy2 from '../pages/cy2'
import main from '../pages/main'

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route path="/betalakecontrolframe" component={Iframe} />
                <Route path="/chatonly" component={widget} />
                <Route path="/cy2" component={cy2} />
                <Route path="/auth" exact strict component={auth} />
                <Route component={main} />
            </Switch>
        </Router>
    )
}