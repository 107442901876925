import React, { useEffect, useRef } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Delete from '@material-ui/icons/Delete';

import SiteMenu from '../components/SiteMenu';
import ChatMenu from '../components/ChatMenu';
import AddMenu from '../components/AddMenu';
import ProfileMenu from '../components/ProfileMenu';
import Socket from '../components/Socket';
import BCytoscape from '../components/BCytoscape';
import BFloatMenuManager from '../components/BFloatMenuManager';

// eslint-disable-next-line
export default function () {
    var bcytoscape = useRef()
    var bfloatmenumanager = useRef()
    var socket = useRef()

    const postToSocket = React.useCallback((event) => {
        socket.current.post(event)
    }, [socket])

    const handleAddNode = React.useCallback((event) => {
        if (event) { event.preventDefault() }
        let image = {
            title: "test_ws_node",
            type: "placeholder",
            content: "Special Containment Procedures: SCP-184 is not to be contained in any structure. SCP-184 is to be attached to a high-power electromagnet at all times. Should the electromagnet fail, agents are to report to SCP-184's containment area and prevent access to all unauthorized personnel until the electromagnet is restored to power. The containment area for SCP-184 is currently configured to resemble a park with SCP-184 and its containment magnet disguised as statuary. Any and all visitors are to be monitored"
        }
        postToSocket({ operation_type: "add", resource_type: "node", image: image })
    }, [postToSocket])
    const handleRemoveNode = React.useCallback((node_id) => {
        let image = {id: node_id}
        postToSocket({ operation_type: "delete", resource_type: "node", image: image })
    }, [postToSocket])

    const handleAddEdge = React.useCallback((event) => {
        if (event) { event.preventDefault() }
        bcytoscape.current.toggleEdgeDrawingMode()
    }, [])
    const handleRemoveEdge = React.useCallback((edge_id) => {
        let image = {id: edge_id}
        postToSocket({ operation_type: "delete", resource_type: "edge", image: image })
    }, [postToSocket])
    const handleAnimate = React.useCallback(() => {
        bcytoscape.current.animate()
    }, [])

    const addActions = [
        { icon: <Avatar />, name: 'AddNode', onClick: handleAddNode },
        { name: 'AddEdge', onClick: handleAddEdge },
        { name: 'Reset Animation', onClick: handleAnimate }
    ]

    const siteActions = [
        { icon: <Delete />, name: 'Delete All', onClick: () => {
            postToSocket({ operation_type: "delete_all", resource_type: "all" })
         }},
    ]

    const onSocketEvent = React.useCallback((event) => {
        console.log("onSocketEvent", event)
        let graph_component = {}

        if (event.operation_type === "add") {
            if (event.resource_type === "node") {
                graph_component.nodes = [event.image]
                bcytoscape.current.addDataToGraph(graph_component)
            }
            if (event.resource_type === "edge") {
                graph_component.edges = [event.image]
                bcytoscape.current.addDataToGraph(graph_component)
            }
            if (event.resource_type === "graph") {
                graph_component.nodes = event.nodes
                graph_component.edges = event.edges
                bcytoscape.current.addDataToGraph(graph_component)
            }
        }
        else if (event.operation_type === "delete") {
            if (event.resource_type === "node") {
                bcytoscape.current.removeElementFromGraph(event.image.id)
            }
            if (event.resource_type === "edge") {
                bcytoscape.current.removeElementFromGraph(event.image.id)
            }
        }
    }, [bcytoscape])

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Socket 
                ref={socket} 
                onEvent={onSocketEvent} />
            <BFloatMenuManager 
                ref={bfloatmenumanager}/>
            <AddMenu actions={addActions} />
            <ProfileMenu />
            <SiteMenu actions={siteActions} />
            <ChatMenu />
            <BCytoscape 
                ref={bcytoscape} 
                removeNode={handleRemoveNode}
                removeEdge={handleRemoveEdge}/>
        </>
    )
}
