
import axios from 'axios';
import config from './config.json';

// Use dummy function as default
export default function pleaseStopMessingWithThis() {
    console.log('You shouldn\'t be here')
}

export function log(message, logging_enabled=true) {
    if (!logging_enabled) {return}
    console.log(message)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function checkNested(obj, level,  ...rest) {
    if (obj === undefined) return false
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function callIfFunction(func, input) {
    log("in call if func")
    if (func) {
        log("it exists")
        if (isFunction(func)){
            log("and it's a function")
            func(input)
        }
    }
}

/////////////////////////////////
// Common Values functionality //
/////////////////////////////////
export function getEndpoint() {
    let endpoint = window.location.host
    if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
        //console.log("Running in development mode, targeting dev endpoint")
        endpoint = config.endpoint;
    }
    // if the source is not in my list of endpoints return none
    if (endpoint.endsWith("betalake.com")) {
        return endpoint
    }
    return null
}

export function getIdToken() {
    return getLocalStorageItem("IdToken")
}
export function getAccessToken() {
    return getLocalStorageItem("AccessToken")
}
export function getRefreshToken() {
    return getLocalStorageItem("RefreshToken")
}

export function getJWTToken () {
    var token = getIdToken()
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export function isTokenExpired() {
    try {
        var token = getJWTToken()
        if (!token) {return true}
        var exp = token.exp
        var now = Date.now()/1000
        // 5 minute buffer on token expiration
        if (now > (exp - (5*60))) {return true}
        return false
    } catch (e) {
        log("token exp err: " + e)
        return true
    }
}

export function getLocalStorageItem(name) {
    return localStorage.getItem(name)
}

export function handleSuccessfullAuthResponse(res) {
    if (!checkNested(res, 'data', 'AuthenticationResult')) {return}
    localStorage.setItem("IdToken", res.data.AuthenticationResult.IdToken)
    localStorage.setItem("AccessToken", res.data.AuthenticationResult.AccessToken)
    if (res.data.AuthenticationResult.RefreshToken){
        localStorage.setItem("RefreshToken", res.data.AuthenticationResult.RefreshToken)
    }
    return
}

// Returns true if new tokens were generated, false if they were not
export function generateNewTokens() {
    return makeAPICall("/public/auth", {refreshtoken: getRefreshToken()})
}

export function logout() {
    localStorage.clear()
    window.location.href = "/"
}

////////////////////////
// REST functionality //
////////////////////////

function makeAPICall(path, body={}, requestConfig={}, token="") {
    // Determine proper endpoint
    let endpoint = "https://" + getEndpoint() + "/api"

    // Build requestConfig
    requestConfig.headers = {
        'accept': "application/json"
    }
    // Add the auth token if it is present
    if (token) {
        requestConfig.headers.Authorization = token
    }
    else if (getIdToken()) {
        requestConfig.headers.Authorization = getIdToken()
    }

    // Build the body
    if (getLocalStorageItem('session_id')) {
        body.session_id = getLocalStorageItem('session_id')
    }

    return axios.post(endpoint + path, body, requestConfig)
}

export function postAPI(path, body={}, requestConfig={}) {
    if (!path.startsWith("/public") && isTokenExpired()) {
        generateNewTokens()
            .then((res) => {
                handleSuccessfullAuthResponse(res) 
                return makeAPICall(path, body, requestConfig, res.data.AuthenticationResult.IdToken)
            })
            .catch((err) => {
                window.location.pathname = "/auth"
                return makeAPICall(path, body, requestConfig)
            })
    }
    return makeAPICall(path, body, requestConfig)
}

/////////////////////////////
// Websocket functionality //
/////////////////////////////

export function getRoom() {
    if (!getEndpoint()) {
        return null
    }
    var room = localStorage.getItem("room")
    if (room !== null) {
        return {name: room, new:false}
        // return room
    }
    else {
        let newroom = newGuid()
        localStorage.setItem("room", newroom)
        return {name: newroom, new:true}
        //return newroom
    }
}

export function clearRoom() {
    localStorage.removeItem("room")
}

////////////////////////
// GUID functionality //
////////////////////////
export function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
}
export function newGuid() {
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}
 


