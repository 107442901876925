import React from 'react'
import ControlFrame from '../components/ControlFrame'

// eslint-disable-next-line
export default function () {
    return (
        <div>
            <ControlFrame />
        </div>
    )
}
