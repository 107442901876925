import React, { useEffect, forwardRef, useImperativeHandle } from 'react';

import BFloatMenu from '../components/BFloatMenu';

// eslint-disable-next-line
export default forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        internalLog(data) {
            console.log("inside the BFloatMenuManager")
            console.log(data)
        },
    }));

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                <div style={{ position: "absolute" }}>
                    {
                        // <BFloatMenu style={{ position: "absolute" }} />
                    }
                </div>
            }
        </>
    )
})
