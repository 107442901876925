import React, { useEffect, useState, useRef } from 'react'
import 'react-chat-widget/lib/styles.css';
import '../media/styleoverride.css'

import * as util from '../utils'

// eslint-disable-next-line
export default function (props) {

    // eslint-disable-next-line
    // const [webSocket, setwebSocket] = useState(null);
    const webSocket = useRef(null)
    const [endpoint, ] = useState(util.getEndpoint())
    const [room, ] = useState(util.getRoom())
    const logging_enabled = false

    // External communication
    const postToWebsocket = React.useCallback((message) => {
        console.log("postToWebsocket", message)
        if (webSocket) {
            try {
                webSocket.current.send(JSON.stringify(message))
            } catch (error) {
                util.log("error sending message to websocket", logging_enabled)
            }
            
        }
        else {
            util.log("Skipping message as websocket isn't initialized")
        }
    }, [webSocket, logging_enabled])
    const postToParent = (message_type, body) => {
        let message = {
            source: "betalake_control_frame",
            message_type: message_type,
            body: body
        }
        window.parent.postMessage(message, "*");
    }

    const onLocalEvent = React.useCallback((event) => {
        if (event.data.source === undefined) {return}
        if (event.data.source === 'betalake_control_frame') {return}
        if (String(event.data.source).startsWith("react")) {return}
        util.log("ControlFrame Got an event: " + JSON.stringify(event.data), logging_enabled)
        if (event.data.message_type === "control_frame_request") {
            util.log("websocket on control_frame_request")
            // util.log(webSocket)
            postToWebsocket(event.data.body)
        }
    }, [postToWebsocket, logging_enabled])
    

    // Websocket components
    const onWebSocketConnect = React.useCallback((event) => {
        event.currentTarget.send(JSON.stringify({request: "get_all"}))
        //setwebSocket(event.currentTarget)
        postToParent("websocket_event", {state:"connected"})
    }, [])
    const onWebSocketDisconnect = (event) => {
        util.log("Disconnected", logging_enabled)
        postToParent("websocket_event", {state:"disconnected"})
        util.log(event, logging_enabled)
    }
    const onWebSocketMessage = (event) => {
        let data = JSON.parse(event.data)
        // postToParent("server_message", data)
        util.log("websocket_message", logging_enabled)
        util.log(data, logging_enabled)
        postToParent("websocket_message", data)
    }


    // Run Once on final load
    useEffect(() => {
        window.addEventListener("message", onLocalEvent)
        // Generate my websocket
        webSocket.current = new WebSocket("wss://" + endpoint + "/websocket/?index=" + room.name) // + "&idtoken=" + idtoken) 
        webSocket.current.onopen = (event) => onWebSocketConnect(event)
        webSocket.current.onclose = (event) => onWebSocketDisconnect(event)
        webSocket.current.onmessage = (event) => onWebSocketMessage(event)
        util.log("control frame loaded", logging_enabled)
        return () => {
            window.removeEventListener("message", onLocalEvent)
            webSocket.current.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logging_enabled]);


    return (
            <div></div>
    )
}
