import React from 'react'
import ChatWidget from '../components/ChatWidget'

const mystyle = {
    pointerEvents: 'auto',
    zIndex: 0
}

// eslint-disable-next-line
export default function () {
    return (
        <div>
            <ChatWidget style={mystyle}/>
        </div>
    )
}
