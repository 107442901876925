import React from 'react'
import Auth from './auth'
import Cy from './cy2'
import * as util from '../utils'



// eslint-disable-next-line
export default function () {
    const [inAuth, setInAuth] = React.useState(false)
    const [inCy, setInCy] = React.useState(false)

    const onAuthSuccess = React.useCallback((auth_result) => {
        util.log("successful log in result in main")
        setInAuth(false)
        setInCy(true)
    }, [])
    
    React.useEffect(() => {
        if (util.isTokenExpired()) {
            setInAuth(true)
        } else {
            setInCy(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {inAuth ? <Auth onAuthSuccess={onAuthSuccess}/> : <div></div>}
            {inCy ? <Cy/> : <div></div>}
        </>
        
    )
}
