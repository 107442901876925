import React from 'react';
import { GridButton } from '../components/Common'

import { IconButton } from '@material-ui/core';
import { Close, Minimize } from '@material-ui/icons';


export function ContinueButton(props) {
    return (
        <GridButton {...props}>
            Continue
        </GridButton>
    )
}

export function SubmitButton(props) {
    return (
        <GridButton {...props}>
            Submit
        </GridButton>
    )
}

// Icon buttons for menues

export function CloseIconButton(props) {
    return (
        <IconButton size={'small'} {...props}>
            <Close />
        </IconButton>
    )
}
export function MinimizeIconButton(props) {
    return (
        <IconButton size={'small'} {...props}>
            <Minimize />
        </IconButton>
    )
}
