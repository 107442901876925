import React, { useEffect } from 'react';

import BSpeedDial from './BSpeedDial';
import * as util from '../utils';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Logout from '@material-ui/icons/ExitToApp';


// eslint-disable-next-line
export default function (props) {

    const tempLog = (event) => {
        console.log(event);
    }

    const logout = (event) => {
        console.log("logout");
        util.logout();
    }

    const addActions = [
        { icon: <Avatar />, name: 'Edit Profile', onClick: tempLog },
        { icon: <Logout />, name: 'Logout', onClick: logout },
    ]

    useEffect(() => {

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <BSpeedDial
                icon={<Person fontSize="large"/>}
                posname='top-right'
                actions={addActions}
                direction={"down"}
            />
        </>
    )
}
