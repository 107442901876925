import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import 'react-chat-widget/lib/styles.css';
import '../media/styleoverride.css'

import * as util from '../utils'

// eslint-disable-next-line
export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        post(data) {
            console.log(data)
            postToWebsocket(data)
        }
    }));
    const webSocket = useRef(null)
    const [endpoint, ] = useState(util.getEndpoint())
    const [room, ] = useState(util.getRoom())
    const [idtoken, ] = useState(util.getIdToken())
    const logging_enabled = false

    const postToWebsocket = React.useCallback((message) => {
        console.log("postToWebsocket", message)
        if (webSocket) {
            try {
                webSocket.current.send(JSON.stringify(message))
            } catch (error) {
                util.log("error sending message to websocket", logging_enabled)
            }
        }
        else {
            util.log("Skipping message as websocket isn't initialized")
        }
    }, [webSocket, logging_enabled])

    // External communication
    const ping = React.useCallback(() => {
        //webSocket.current.send("__ping__")
        postToWebsocket({"type": "ping"})
        setTimeout(ping, 60000) 
    }, [postToWebsocket])

    // Websocket components
    const onWebSocketConnect = React.useCallback((event) => {
        event.currentTarget.send(JSON.stringify({request: "get_all"}))
        ping()
    }, [ping])
    const onWebSocketDisconnect = (event) => {
        util.log("Disconnected", logging_enabled)
        util.log(event, logging_enabled)
    }
    const onWebSocketMessage = (event) => {
        let data = JSON.parse(event.data)
        util.log("websocket_message", logging_enabled)
        util.log(data, logging_enabled)
        props.onEvent(data)
    }

    useEffect(() => {
        let connection_string = "wss://" + endpoint + "/websocket/?index=" + room.name
        console.log("starting wss connection")
        console.log("idtoken", idtoken)
        if (idtoken) {
            connection_string += "&idtoken=" + idtoken
        }
        webSocket.current = new WebSocket(connection_string) // + "&idtoken=" + idtoken) 
        webSocket.current.onopen = (event) => onWebSocketConnect(event)
        webSocket.current.onclose = (event) => onWebSocketDisconnect(event)
        webSocket.current.onmessage = (event) => onWebSocketMessage(event)
        util.log("control frame loaded", logging_enabled)
        return () => {
            webSocket.current.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logging_enabled]);


    return (
            <div></div>
    )
})
