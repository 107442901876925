import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// eslint-disable-next-line
export default function (props) {
    const [actions, ] = React.useState(props.actions || [])
    const borderSpacing = props.borderSpacing || 16
    const sxMap = {
        ...{position: 'absolute'},
        ...props.posname && props.posname.includes("left") ? {left: borderSpacing} : {},
        ...props.posname && props.posname.includes("bottom") ? {bottom: borderSpacing} : {},
        ...props.posname && props.posname.includes("right") ? {right: borderSpacing} : {},
        ...props.posname && props.posname.includes("top") ? {top: borderSpacing} : {},
    }
    const defaultActionIcon = props.defaultIcon || <SpeedDialIcon/>

    return (
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={sxMap}
                icon={props.icon || <SpeedDialIcon/>}
                {...props}
            >
                {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon || defaultActionIcon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
                ))}
            </SpeedDial>
    );
}
