import React, { useEffect } from 'react';

import BSpeedDial from './BSpeedDial';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/icons/Menu';


// eslint-disable-next-line
export default function (props) {

    const tempLog = (event) => {
        console.log(event);
    }

    const addActions = props.actions || [
        { icon: <Avatar />, name: 'Edit Profile', onClick: tempLog },
    ]

    useEffect(() => {

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <BSpeedDial
                icon={<Menu fontSize="large"/>}
                posname='top-left'
                actions={addActions}
                direction={"down"}
            />
        </>
    )
}
